body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400;700&display=swap');

* {
  font-family: 'Cairo', sans-serif;
}


@tailwind base;
@tailwind components;
@tailwind utilities;


/* 
.ant-table-body::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.ant-table-body::-webkit-scrollbar-thumb {
  background-color: #217346;
  border-radius: 10px;
} */



/* .ant-table-body::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}

.ant-table-body::-webkit-scrollbar-thumb {
  background-color: #217346 !important;
  border-radius: 10px !important;
} */


